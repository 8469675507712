import { all, takeLatest, call, put, delay } from "redux-saga/effects";

import api from "../../../services/api";
import { 
  FuncaoTypes, 
  addFuncoes, 
  addFuncao,
  addUnliked,
  addFuncoesGrouped,
  addFuncoesGroupedByCargo,
  get as getAllFuncoes,
  add,
  getGrouped as getFuncoesGrouped
} from "./actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { logout } from "../auth/actions";

export function* create({ payload }) {
  const { funcao, setModalFuncao } = payload;
  const response = yield call(api, "post", "funcao", funcao);

  const { data, status } = response;
  switch (status) {
    case 200:
      setModalFuncao(false);
      yield put(
        addToast({
          title: "Função criada",
          type: "success",
        })
      );
      yield put(getAllFuncoes());
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 409:
      setModalFuncao(true);
      yield put(
        addToast({
          title: `Função com nome ${funcao.name} já cadastrada.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: 'Dados inválidos:',
          text: data,
          type: "warning",
        })
      );
    break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* update({ payload }) {
  const { id, funcao, setModalFuncao } = payload;
  const response = yield call(api, "put", `funcao/${id}`, funcao);

  const { data, status } = response;
  switch (status) {
    case 200:
      setModalFuncao(false);
      yield put(
        addToast({
          title: "Função atualizada",
          type: "success",
        })
      );
      yield put(getAllFuncoes());
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Função com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 406:
      yield put(
        addToast({
          title: 'Função generica não autorizada para edição.',
          type: "warning",
        })
      );
      break;
    case 409:
      setModalFuncao(true);
      yield put(
        addToast({
          title: `Função com nome ${funcao.name} já cadastrada.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* vincularSetor({ payload }) {
  const { funcao, id } = payload;
  const response = yield call(api, "patch", `funcao/${id}`, funcao);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Função vinculada",
          type: "success",
        })
      );
      yield put(getFuncoesGrouped());
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Função com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 406:
      yield put(
        addToast({
          title: 'Função já possui vínculo de setor.',
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* get({ payload }) {
  yield delay(50);

  const { search, filterStatus, filterExecucao, filterSetor, filterCargos, setLoading } = payload;
  const response = yield call(
    api, 
    'get', 
    '/funcoes', 
    {}, 
    {
      search, filterStatus, filterExecucao, filterSetor, filterCargos
    }
  );
  const { data, status } = response;
  
  switch (status) {
    case 200:
      // eslint-disable-next-line no-case-declarations
      var funcoesOptions = [];

      data && data.map((funcao) => {
        funcoesOptions.push({value: funcao.id, label: funcao.name});
      })

      yield put(add(data, funcoesOptions));

      if (setLoading !== undefined) {
        setLoading(false);
      }
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getById({ payload }) {
  const { id } = payload;
  const response = yield call(api, "get", `funcoes/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(addFuncao(data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Função com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getUnliked() {
  yield delay(50);

  const response = yield call(api, 'get', '/funcoes-sem-setor', {}, {});
  const { data, status } = response;
  
  switch (status) {
    case 200:
      var optionsFuncoesUnliked = [];

      data && data.map((funcao) => {
        optionsFuncoesUnliked.push({ value: funcao.id, label: funcao.name, isFixed: true });
      });
      // eslint-disable-next-line no-case-declarations
      yield put(addUnliked(optionsFuncoesUnliked));
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getGrouped() {
  yield delay(50);

  const response = yield call(api, 'get', '/all-funcoes', {}, {});
  const { data, status } = response;
  
  switch (status) {
    case 200:
      var funcoesAgrupadas = [];
      var funcoesAgrupadasComSetor = [];

      data && data.map((item) => {
        var options = [];
        var optionsComSetor = [];

        item.options.map((option) => {
          options.push({ value: option.funcao_id, label: option.setor_funcao, isFixed: true });

          if (option.setor_funcao.includes("N/A")) {
            optionsComSetor.push({ value: option.funcao_id, label: option.setor_funcao, isFixed: true }); 
          }
        })
        funcoesAgrupadas.push({label: item.group, options: options, setor_id: item.setor_id});
        funcoesAgrupadasComSetor.push({label: item.group, options: optionsComSetor})
      })

      // eslint-disable-next-line no-case-declarations
      yield put(addFuncoesGrouped(funcoesAgrupadas, funcoesAgrupadasComSetor));
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getGroupedByCargo({ payload }) {
  yield delay(50);

  const { cargo_id } = payload;

  const response = yield call(api, 'get', `/funcoes/cargo/${cargo_id}`, {}, {});
  const { data, status } = response;

  switch (status) {
    case 200:
      var funcoesAgrupadasByCargo = [];

      data && data.map((item) => {
        var options = [];
        item.options.map((option) => {
          options.push({ value: option.funcao_id, label: option.setor_funcao, isFixed: true });
        })
        funcoesAgrupadasByCargo.push({label: item.group, options: options});
      })

      // eslint-disable-next-line no-case-declarations
      yield put(addFuncoesGroupedByCargo(funcoesAgrupadasByCargo));
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* deleteFuncao({ payload }) {
  const { id } = payload;
  const response = yield call(api, "delete", `funcoes/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      history.go("/procedimentos");
    case 201:
      history.go("/procedimentos");
      break;
    case 404:
      yield put(
        addToast({
          title: `Função não encontrada.`,
          type: "warning",
        })
      );
    break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(FuncaoTypes.CREATE, create),
  takeLatest(FuncaoTypes.UPDATE, update),
  takeLatest(FuncaoTypes.VINCULAR_SETOR, vincularSetor),
  takeLatest(FuncaoTypes.GET, get),
  takeLatest(FuncaoTypes.GET_UNLIKED, getUnliked),
  takeLatest(FuncaoTypes.GET_BY_ID, getById),
  takeLatest(FuncaoTypes.GET_GROUPED, getGrouped),
  takeLatest(FuncaoTypes.GET_GROUPED_BY_CARGO, getGroupedByCargo),
  takeLatest(FuncaoTypes.DELETE, deleteFuncao),
]);
