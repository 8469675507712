import produce from 'immer';

import { CargosTypes } from './actions';

export const INITIAL_STATE = {
    listarCargos: [],
    listarCargosFilhos: [],
    status:null, 
    cargos: null
};


export default function cargos(state = INITIAL_STATE, action) {
    return produce(state, draft => {

        switch (action.type) {
            case CargosTypes.SET: {
                draft.listarCargos = action.payload.data;
                break;
            }
            case CargosTypes.ADD_CHILDREN: {
                draft.listarCargosFilhos = action.payload.data;
                break;
            }
            case CargosTypes.SET_STATUS:
                return {
                  ...state,
                  status: action.payload,
                };
            default:
        }
    })
}