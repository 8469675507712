import React from "react";

export const CargosTypes = {
  GET: "@Cargos/GET",
  SET: "@Cargos/SET",
  GET_CHILDREN: "@Cargos/GET_CHILDREN",
  ADD_CHILDREN: "@Cargos/ADD_CHILDREN",
  SET_STATUS: "@Cargos/SET_STATUS",
};


export function listarCargos() {
  return {
    type: CargosTypes.GET

  }
}
export function listarCargosFilhos() {
  return {
    type: CargosTypes.GET_CHILDREN

  }
}

export function setCargos(data) {
  return {
    type: CargosTypes.SET,
    payload: {
      data,
    },
  };
}

export function setStatus(status) {
  return {
    type: CargosTypes.SET_STATUS,
    payload: status,
  };
}


export function addChildren(data) {
  return {
    type: CargosTypes.ADD_CHILDREN,
    payload: {
      data,
    },
  };
}