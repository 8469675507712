import { all, takeLatest, call, put } from "redux-saga/effects";

import api from "../../../services/api";
import { EtapaTypes, add } from "./actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { logout } from "../auth/actions";

export function* get() {
  const response = yield call(api, "get", "etapas");

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(add(data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* create({ payload }) {
  const { etapa } = payload;
  const response = yield call(api, "post", "etapas", etapa);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      yield put(
        addToast({
            title: "Etapa criada com sucesso.",
            type: "success",
        })
      );
      history.go("/estrutura");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: `Etapa com nome ${etapa.name} já cadastrada.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: 'Dados inválidos:',
          text: data,
          type: "warning",
        })
      );
    break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* update({ payload }) {
  const { id, etapa } = payload;
  const response = yield call(api, "put", `etapas/${id}`, etapa);

  const { status } = response;
  switch (status) {
    case 200:
    case 201:
      yield put(
        addToast({
            title: "Dados atualizados com sucesso.",
            type: "success",
        })
      );
      history.go("/estrutura");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Etapa com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: `Etapa com nome ${etapa.name} já cadastrada.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* deleteEtapa({ payload }) {
  const { id } = payload;
  const response = yield call(api, "delete", `etapas/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      yield put(
        addToast({
          title: "Etapa excluída com sucesso",
          type: "success",
        })
      );
      window.location.reload(true);
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Etapa com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* changePosition({ payload }) {
  const { dados } = payload;
  const response = yield call(api, "patch", `etapas/changePosition`, dados);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Etapa modificada com sucesso",
          type: "success",
        })
      );
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(EtapaTypes.GET, get),
  takeLatest(EtapaTypes.CREATE, create),
  takeLatest(EtapaTypes.UPDATE, update),
  takeLatest(EtapaTypes.DELETE, deleteEtapa),
  takeLatest(EtapaTypes.CHANGE_POSITION, changePosition),
]);
