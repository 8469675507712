import { all, takeLatest, call, put, delay, select } from "redux-saga/effects";

import api from "../../../services/api";
import {
  ProcedimentoTypes,
  addProcedimento,
  addVersoes,
  addVersaoProcedimento,
  get as getAllProcedimentos,
  add,
  addOptions,
  setStatus,
  setRegistroAtividade,
  addStatus,
  addPop,
  adicionarRegistros,
  deletePopSuccess,
} from "./actions";
import { get as getProcessos } from "../processo/actions";
import { get as getFuncoes } from "../funcao/actions";
import { getSetor } from "../setor/actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { logout } from "../auth/actions";

export function* create({ payload }) {
  const { procedimento, setModal, preCadastro } = payload;
  const response = yield call(api, "post", "procedimento", procedimento);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      if (setModal !== undefined) {
        setModal(false);
      }

      yield put(
        addToast({
          title: "Procedimento cadastrado",
          type: "success",
        })
      );
      yield put(addPop(data));
      if (preCadastro) {
        history.push(`/procedimento/continuar/${window.btoa(data.id)}`);
      }
      break;
    case 402:
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: `Procedimento com nome ${procedimento.name} já cadastrado.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: "Dados inválidos:",
          text: data,
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* listarRegistroAtividades(payload) {
  const { id } = payload.payload;

  const response = yield call(
    api,
    "get",
    `/v2/procedimentos/${id}/registro_atividades`
  );
  const { data, status } = response;
  yield put(adicionarRegistros(data));
}

export function* registrarAtividade({ payload }) {
  try {
    const {
      procedimento_id,
      status_id,
      notas,
      anexos,
      setModal,
    } = payload.registroAtividade;

    const formData = new FormData();
    formData.append("status_id", status_id);
    formData.append("notas", notas);

    // Adicionar cada arquivo ao FormData
    anexos.forEach((anexo, index) => {
      formData.append(`anexo${index + 1}`, anexo);
    });

    const response = yield call(
      api,
      "post",
      `/v2/procedimentos/${procedimento_id}/registro_atividades`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const { data, status } = response;

    if (status === 201) {
      if (setModal !== undefined) {
        setModal(false);
      }

      yield put(
        addToast({
          title: "Registro de atividade cadastrado",
          type: "success",
        })
      );
    } else if (status === 404) {
      yield put(
        addToast({
          title: `Atividade não registrada.`,
          type: "warning",
        })
      );
    } else {
      yield put(
        addToast({
          title: "Atividade não registrada.",
          type: "danger",
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* listarStatusAtividades() {
  const response = yield call(
    api,
    "get",
    "/v2/procedimentos/registro_atividades/status/",
    {},
    {}
  );
  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(addStatus(data));
      break;
    case 404:
      yield put(
        addToast({
          title: `atividades não encontradas.`,
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* update({ payload }) {
  const { id, procedimento, url } = payload;

  const response = yield call(
    api,
    "post",
    `procedimento/${id}?_method=PUT`,
    procedimento
  );

  const { data, status } = response;
  switch (status) {
    case 200:
      if (url) {
        yield put(getSetor());
        yield put(getFuncoes());
      } else {
        window.location.href = `/procedimentos`;
      }
      break;
    case 201:
      if (url) {
        yield put(getSetor());
        yield put(getFuncoes());
      } else {
        window.history.back();
      }
      break;
    case 402:
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Procedimento com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      history.push("/procedimentos");
      break;
    case 422:
      yield put(
        addToast({
          title: "Dados inválidos:",
          text: data,
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* updateOnly({ payload }) {
  const { id, procedimento } = payload;

  const response = yield call(
    api,
    "put",
    `procedimento/${id}/update`,
    procedimento
  );

  const { data, status, config } = response;
  const exec = JSON.parse(config.data);

  switch (status) {
    case 200:
    case 201:
      yield put(getAllProcedimentos());
      yield put(
        addToast({
          title:
            "Procedimento modificado com sucesso para " + exec.execucao + ".",
          type: "success",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Procedimento com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      history.push("/procedimentos");
      break;
    case 422:
      yield put(
        addToast({
          title: "Dados inválidos:",
          text: data,
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* get({ payload }) {
  yield delay(50);
  const {
    search,
    filterStatus,
    filterExecucao,
    filterSetor,
    filterCargos,
    filterMember,
    filterTempo,
    setLoading,
  } = payload;

  let response = yield call(
    api,
    "get",
    "/procedimentos",
    {},
    {
      search,
      filterStatus,
      filterExecucao,
      filterSetor,
      filterCargos,
      filterMember,
      filterTempo,
    }
  );

  if (response.data == "") {
    response.data = 1;
  }

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(add(data));
      yield put(setStatus(status));
      // yield put(setStatus(""));
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* getAll() {
  yield delay(50);

  const response = yield call(api, "get", "/procedimentos-funcoes", {}, {});
  const { data, status } = response;

  switch (status) {
    case 200:
      var optionsProcedimentos = [];
      var optionsProcedimentosWithoutEtapa = [];

      data &&
        data.map(procedimento => {
          if (!procedimento.funcoes) {
            optionsProcedimentos.push({
              value: procedimento.id,
              label: procedimento.name,
              isFixed: true,
            });
          }

          if (!procedimento.etapa_id) {
            optionsProcedimentosWithoutEtapa.push({
              value: procedimento.id,
              label: procedimento.name,
              isFixed: true,
            });
          }
        });
      // eslint-disable-next-line no-case-declarations
      yield put(
        addOptions(optionsProcedimentos, optionsProcedimentosWithoutEtapa)
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* getById({ payload }) {
  const { id, redirect } = payload;
  const response = yield call(api, "get", `/procedimento/${id}`, {}, {});
  const { data, status } = response;
  switch (status) {
    case 200:
      var optionsVersoes = [];
      if (data.versoes.length > 0) {
        data.versoes.map(versao => {
          var date = new Date(versao.created_at);
          if (versao.atual) {
            optionsVersoes.push({
              value: versao.id,
              label: `V.${versao.versao} - ${date.toLocaleDateString(
                "pt-BR"
              )} (Atual)`,
              isFixed: true,
            });
          } else {
            optionsVersoes.push({
              value: versao.id,
              label: `V.${versao.versao} - ${date.toLocaleDateString("pt-BR")}`,
              isFixed: true,
            });
          }
        });
      }

      // eslint-disable-next-line no-case-declarations
      yield put(addProcedimento(data));
      yield put(addVersoes(optionsVersoes));
      yield put(setStatus(status));

      if (redirect) {
        history.push(`/procedimento/continuar/${window.btoa(id)}`);
      }
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      // eslint-disable-next-line no-case-declarations
      yield put(
        addToast({
          title: "Procedimento não encontrado.",
          type: "warning",
        })
      );
      history.push("/procedimentos");
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* getByVersaoId({ payload }) {
  yield delay(50);

  const response = yield call(api, "get", `/versao/${payload.id}`, {}, {});
  const { data, status } = response;

  switch (status) {
    case 200:
      // eslint-disable-next-line no-case-declarations
      yield put(addVersaoProcedimento(data));
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      // eslint-disable-next-line no-case-declarations
      yield put(
        addToast({
          title: "Versão do procedimento não encontrada.",
          type: "warning",
        })
      );
      history.push("/procedimentos");
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* restore({ payload }) {
  yield delay(50);

  const response = yield call(api, "put", `/versao/${payload.id}`, {}, {});
  const { data, status } = response;

  switch (status) {
    case 200:
      history.push(`/procedimento/${data.procedimento_id}`);
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      // eslint-disable-next-line no-case-declarations
      yield put(
        addToast({
          title: "Versão do procedimento não encontrada.",
          type: "warning",
        })
      );
      history.push("/procedimentos");
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* vincularProcesso({ payload }) {
  const { id, informacoes } = payload;
  const response = yield call(
    api,
    "put",
    `procedimentos/${id}/vincularProcesso`,
    informacoes
  );

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Procedimento vinculado",
          type: "success",
        })
      );
      yield put(getProcessos());
      yield put(getAllProcedimentos());
      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Procedimento com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* desvincularProcesso({ payload }) {
  const { id } = payload;
  const response = yield call(
    api,
    "put",
    `procedimentos/${id}/desvincularProcesso`
  );

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Procedimento desvinculado",
          type: "success",
        })
      );
      yield put(getProcessos());
      yield put(getAllProcedimentos());
      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Procedimento com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* deleteProcedimento({ payload }) {
  const { id } = payload;
  const response = yield call(api, "delete", `procedimentos/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
    // history.go("/procedimentos");
    // yield put(deletePopSuccess(data));
    case 201:
      // history.go("/procedimentos");
      break;
    case 404:
      yield put(
        addToast({
          title: `Procedimento não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* vincularEtapa({ payload }) {
  const { dados } = payload;
  const response = yield call(api, "put", `procedimentos/vincularEtapa`, dados);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Procedimentos vinculados",
          type: "success",
        })
      );
      window.location.reload(true);
      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export function* changePosition({ payload }) {
  const { dados } = payload;
  const response = yield call(
    api,
    "patch",
    `procedimentos/changePosition`,
    dados
  );

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Procedimento modificado com sucesso",
          type: "success",
        })
      );
      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title: data.error,
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(ProcedimentoTypes.CREATE, create),
  takeLatest(ProcedimentoTypes.UPDATE, update),
  takeLatest(ProcedimentoTypes.UPDATE_ONLY, updateOnly),
  takeLatest(ProcedimentoTypes.GET, get),
  takeLatest(ProcedimentoTypes.GET_ALL, getAll),
  takeLatest(ProcedimentoTypes.GET_BY_ID, getById),
  takeLatest(ProcedimentoTypes.GET_BY_VERSAO_ID, getByVersaoId),
  takeLatest(ProcedimentoTypes.RESTORE, restore),
  takeLatest(ProcedimentoTypes.VINCULAR_PROCESSO, vincularProcesso),
  takeLatest(ProcedimentoTypes.DESVINCULAR_PROCESSO, desvincularProcesso),
  takeLatest(ProcedimentoTypes.DELETE, deleteProcedimento),
  takeLatest(ProcedimentoTypes.VINCULAR_ETAPA, vincularEtapa),
  takeLatest(ProcedimentoTypes.CHANGE_POSITION, changePosition),
  takeLatest(ProcedimentoTypes.REG_ATIVIDADE, registrarAtividade),
  takeLatest(ProcedimentoTypes.LISTAR_ATIVIDADE, listarStatusAtividades),
  takeLatest(
    ProcedimentoTypes.LISTAR_REGISTROS_ATIVIDADES,
    listarRegistroAtividades
  ),
  takeLatest(ProcedimentoTypes.ADD_POP, addPop),
]);
