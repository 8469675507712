import { all, takeLatest, call, put } from "redux-saga/effects";

import api from "../../../services/api";
import {
  OrganizacaoTypes,
  add,
  addById,
  get as getAllOrganizacoes
} from "./actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { store } from "../../../store";
import { logout, setOptionsOrg } from "../auth/actions";

export function* get({ payload }) {
  const { search, filterStatus, filterCategoria, setLoading } = payload;

  const response = yield call(api, "get", "organizacoes", {}, { search, filterStatus, filterCategoria });

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(add(data));

      if (setLoading !== undefined) {
        setLoading(false);
      }
      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getById({ payload }) {
  const { id } = payload;

  const response = yield call(api, "get", `organizacoes/${id}`, {}, {});

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(addById(data));

      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: "Organização não encontrada.",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* create({ payload }) {
  const { organizacao, id } = payload;

  const formData = new FormData();
  formData.append("foto_perfil", organizacao.uploadedFotoPerfil.file);
  formData.append("data", JSON.stringify(organizacao.dados));

  const response = yield call(api, "post", `/organizacoes/${id}`, formData);

  const { data, status } = response;

  switch (status) {
    case 200:
    case 201:
      yield put(
        addToast({
          title: "Organização criada com sucesso.",
          type: "success",
        })
      );
      history.push("/organizacoes");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "O usuário não tem permissão.",
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: ` ${organizacao.name} já cadastrada.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: 'Dados inválidos:',
          text: data,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* update({ payload }) {
  const { id, organizacao } = payload;
  const response = yield call(
    api,
    "post",
    `organizacoes/${id}`,
    organizacao
  );

  const { data, status } = response;
  
  switch (status) {
    case 200:
    case 201:
      history.push(`/organizacao/${id}`);
      yield put(
        addToast({
          title: "Dados atualizados com sucesso.",
          type: "success",
        })
      );
      history.push("/estrutura");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "O usuário não tem permissão.",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Organização com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: data.erro,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* deleteOrganizacao({ payload }) {
  const { id } = payload;
  const response = yield call(api, "delete", `organizacoes/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      yield put(
        addToast({
          title: "Organização excluída com sucesso",
          type: "success",
        })
      );
      history.push("/organizacoes");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "O usuário não tem permissão.",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Organização com id ${id} não encontrada.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getOrgByMember({ payload }) {
  const response = yield call(api, "get", `member/organizacoes/${payload.member_id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      let optionsOrganizacoes = [];

      data && data.map(organizacao => {
        if (organizacao.active) {
          optionsOrganizacoes.push({
            value: organizacao.id,
            label: organizacao.name,
            isFixed: true,
            foto: organizacao.foto_perfil,
            name: organizacao.name,
          });
        }
      });

      yield put(
        setOptionsOrg(optionsOrganizacoes)
      );
      break;
    case 201:
      yield put(
        addToast({
          title: "Organização excluída com sucesso",
          type: "success",
        })
      );
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "O usuário não tem permissão.",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Membro com id ${payload.member_id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* sendEmails({ payload }) {
  const response = yield call(api, "get", `organizacoes/send-emails/${payload.organizacao_id}`);

  const {message, status } = response;
  
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "E-mail enviado para todos os membros com a notificação ativada.",
          type: "success",
        })
      );
      break;
    case 201:
      yield put(
        addToast({
          title: "Organização excluída com sucesso",
          type: "success",
        })
      );
      break;
    case 400:
      yield put(
        addToast({
          title: "Notificações desativadas",
          type: "warning",
        })
      );
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 403:
      yield put(
        addToast({
          title: "O usuário não tem permissão.",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Membro com id ${payload.member_id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(OrganizacaoTypes.GET, get),
  takeLatest(OrganizacaoTypes.GET_BY_ID, getById),
  takeLatest(OrganizacaoTypes.GET_ORG_BY_MEMBER, getOrgByMember),
  takeLatest(OrganizacaoTypes.CREATE, create),
  takeLatest(OrganizacaoTypes.UPDATE, update),
  takeLatest(OrganizacaoTypes.DELETE, deleteOrganizacao),
  takeLatest(OrganizacaoTypes.SEND_EMAIL, sendEmails),
]);
