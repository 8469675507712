export const CargoTypes = {
  GET: "@GARGO/GET",
  SET: "@GARGO/SET",
  CREATE: "@GARGO/CREATE",
  UPDATE: "@GARGO/UPDATE",
  DELETE: "@CARGO/DELETE",
  GET_TREE: "@GARGO/GET_TREE",
  GET_CHILDREN: "@GARGO/GET_CHILDREN",
  GET_PARENT: "@GARGO/GET_PARENT",
  ADD_CHILDREN: "@GARGO/ADD_CHILDREN",
  ADD_PARENT: "@GARGO/ADD_PARENT",
  SET_TREE: "@GARGO/SET_TREE",
  SET_STATUS: "@GARGO/SET_STATUS",
  SET_CARGO_SUCESS: "@CARGO/CREATE_CARGO_SUCCESS",
};

export function getCargo() {
  return {
    type: CargoTypes.GET,
  };
}

export function setCargo(data, optionsCargos, optionsFilterCargo) {
  return {
    type: CargoTypes.SET,
    payload: {
      data,
      optionsCargos,
      optionsFilterCargo,
    },
  };
}

export function setCargoSucesso(cargoSucesso) {
  return {
    type: CargoTypes.SET_CARGO_SUCESS,
    payload: {
      cargoSucesso
    },
  };
}


export function createCargo(cargo, setCreateCargoModal ) {
  return {
    type: CargoTypes.CREATE,
    payload: {
      cargo,
      setCreateCargoModal,
    },
  };
}



export function setStatus(status) {
  return {
    type: CargoTypes.SET_STATUS,
    payload: status,
  };
}



export function updateCargo(cargo, setCreateCargoModal) {
  return {
    type: CargoTypes.UPDATE,
    payload: {
      cargo,
      setCreateCargoModal,
    },
  };
}

export function deleteCargo(id) {
  return {
    type: CargoTypes.DELETE,
    payload: {
      id,
    },
  };
}

export function getTree() {
  return {
    type: CargoTypes.GET_TREE,
  };
}

export function getChildren(id) {
  return {
    type: CargoTypes.GET_CHILDREN,
    payload: {
      id,
    },
  };
}

export function getParent(id) {
  return {
    type: CargoTypes.GET_PARENT,
    payload: {
      id,
    },
  };
}

export function setTree(tree) {
  return {
    type: CargoTypes.SET_TREE,
    payload: {
      tree,
    },
  };
}

export function addChildren(data) {
  return {
    type: CargoTypes.ADD_CHILDREN,
    payload: {
      data,
    },
  };
}

export function addParent(data) {
  return {
    type: CargoTypes.ADD_PARENT,
    payload: {
      data,
    },
  };
}
