import produce from "immer";

import { ProcessosTypes } from "./actions";

export const INITIAL_STATE = {
  processos: null,
  processosTeste: null,
  optionsProcessos: [],
  processo: null,
};

export default function processo(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ProcessosTypes.ADD_TESTE: {
        draft.processosTeste = action.payload.processosTeste;
        break;
      }
      case ProcessosTypes.ADD_OPTIONS: {
        draft.optionsProcessos = action.payload.optionsProcessos;
        break;
      }
      case ProcessosTypes.ADD: {
        draft.processos = action.payload.processos;
        break;
      }
      case ProcessosTypes.ADD_PROCESSO: {
        draft.processo = action.payload.processo;
        break;
      }
      default:
    }
  });
}
