import produce from 'immer';

import { OrganizacaoTypes } from './actions';

export const INITIAL_STATE = {
    organizacoes: null,
    organizacao: null
};

export default function organizacao(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case OrganizacaoTypes.ADD: {
        draft.organizacoes = action.payload.organizacoes;
      }
      case OrganizacaoTypes.ADD_BY_ID: {
        draft.organizacao = action.payload.organizacao;
      }
      default:
    }
  });
}
