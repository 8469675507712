export const GrupoTypes = {
    GET: "@Grupo/GET",
    CREATE: "@Grupo/CREATE",
    ADD: "@Grupo/ADD",
    UPDATE: "@Grupo/UPDATE",
    DELETE: "@Grupo/DELETE",
  };
  
export function get(setLoading) {
    return {
        type: GrupoTypes.GET,
        payload: {
            setLoading
        }
    };
}
  
export function create(grupo) {
    return {
        type: GrupoTypes.CREATE,
        payload: {
            grupo
        },
    };
}

export function add(grupos, optionsGrupos, grupoGeral) {
    return {
        type: GrupoTypes.ADD,
        payload: {
            grupos,
            optionsGrupos,
            grupoGeral
        }
    };
}

export function update(id, grupo) {
    return {
        type: GrupoTypes.UPDATE,
        payload: {
            id,
            grupo,
        },
    };
}

export function deleteGrupo(id) {
    return {
        type: GrupoTypes.DELETE,
        payload: {
            id
        },
    };
}