export const MemberType = {
  GET: "@MEMBER/GET",
  SET_USERS: "@MEMBER/SET_USERS",
  ALL: "@MEMBER/ALL",
  GET_ALL_ORDER: "@MEMBER/GET_ALL_ORDER",
  SET_LIST: "@MEMBER/SET_LIST",
  FIND: "@MEMBER/FIND",
  FIND_PROFILE: "@MEMBER/FIND_PROFILE",
  UPDATE_PROFILE: "@MEMBER/UPDATE_PROFILE",
  SET_PROFILE: "@MEMBER/SET_PROFILE",
  USER: "@MEMBER/USER",
  PHOTO: "@MEMBER/PHOTO",
  CREATE_NOT_ACCESS: "@MEMBER/CREATE_NOT_ACCESS",
  CREATE_ACCESS: "@MEMBER/CREATE_ACCESS",
  MEMBER_INFO_TO_EDIT: "@MEMBER/MEMBER_INFO_TO_EDIT",
  UPDATE_MEMBER: "@MEMBER/UPDATE_MEMBER",
  UPDATE_MEMBER_PHOTO: "@MEMBER/UPDATE_MEMBER_PHOTO",
  INVITE: "@MEMBER/INVITE",
  SWITCH_USER_STATUS: "@MEMBER/SWITCH_USER_STATUS",
  DELETE: "@MEMBER/DELETE_MEMBER",
  VINCULAR_CARGO: "@MEMBER/VINCULAR_CARGO",
  SET_OPTIONS_MEMBER: "@MEMBER/SET_OPTIONS_MEMBER",
  STATUS_MEMBER: "@MEMBER/STATUS_MEMBER",
};

export function all() {
  return {
    type: MemberType.GET,
  };
}

export function setUsers(all_users) {
  return {
    type: MemberType.SET_USERS,
    payload: {
      all_users,
    },
  };
}

export function getAll() {
  return {
    type: MemberType.ALL,
  };
}

export function getAllOrdered(orderBy) {
  return {
    type: MemberType.GET_ALL_ORDER,
    payload: {
      orderBy,
    },
  };
}

export function setList(list) {
  return {
    type: MemberType.SET_LIST,
    payload: {
      list,
    },
  };
}

export function statusMember(status){
  return{
    type:MemberType.STATUS_MEMBER,
    payload: {
      status,
    },
  }
}

export function setOptionsMember(list) {
  return {
    type: MemberType.SET_OPTIONS_MEMBER,
    payload: {
      list,
    },
  };
}

export function getMembers(id) {
  return {
    type: MemberType.FIND,
    payload: {
      id,
    },
  };
}

export function updateMemberProfile(id, dados) {
  return {
    type: MemberType.UPDATE_PROFILE,
    payload: {
      id,
      dados,
    },
  };
}

export function getMemberProfile(id) {
  if (!id) {
    throw new Error("O 'id' é obrigatório no payload.");
  }
  return {
    type: MemberType.FIND_PROFILE,
    payload: {
      id    },
  };
}

export function setMemberProfile(profile) {
  return {
    type: MemberType.SET_PROFILE,
    payload: {
      profile,
    },
  };
}

export function setMember(user, photo) {
  return {
    type: MemberType.USER,
    payload: {
      user,
      photo,
    },
  };
}

export function updatePhoto(photo) {
  return {
    type: MemberType.PHOTO,
    payload: {
      photo,
    },
  };
}

export function createWithNoAccess(new_user) {
  return {
    type: MemberType.CREATE_NOT_ACCESS,
    payload: {
      new_user,
    },
  };
}

export function createWithAccess(new_user) {
  return {
    type: MemberType.CREATE_ACCESS,
    payload: {
      new_user,
    },
  };
}

export function setMembroInfos(member) {
  return {
    type: MemberType.MEMBER_INFO_TO_EDIT,
    payload: {
      member,
    },
  };
}

export function updateMember(id, member) {
  return {
    type: MemberType.UPDATE_MEMBER,
    payload: {
      id,
      member,
    },
  };
}

export function updateMemberPhoto(id, formData) {
  return {
    type: MemberType.UPDATE_MEMBER_PHOTO,
    payload: {
      id,
      formData,
    },
  };
}

export function sendInvite(id) {
  return {
    type: MemberType.INVITE,
    payload: {
      id,
    },
  };
}

export function toggleActiveStatus(id) {
  return {
    type: MemberType.SWITCH_USER_STATUS,
    payload: {
      id,
    },
  };
}


export function deleteMember(id) {
  return {
    type: MemberType.DELETE,
    payload: {
      id,
    },
  };
}

export function vincularCargo(dados) {
  return {
    type: MemberType.VINCULAR_CARGO,
    payload: {
      dados
    },
  };
}