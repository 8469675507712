import React from "react";

export const GruposTypes = {
  GET: "@Grupos/GET",
  SET: "@Grupos/SET",
  SET_STATUS: "@Grupos/SET_STATUS",

};


export function listarGrupos() {
  return {
    type: GruposTypes.GET

  }
}

export function setGrupos(data) {
  return {
    type: GruposTypes.SET,
    payload: {
      data,
    },
  };
}

export function setStatus(status) {
  return {
    type: GruposTypes.SET_STATUS,
    payload: status,
  };
}
