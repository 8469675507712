const routes = [
  {
    title: "Login",
    component: "Login",
    path: "/",
    auth: false,
    exact: true,
  },
  {
    title: "Bem Vindo",
    component: "BemVindo",
    path: "/bem-vindo",
    auth: false,
    exact: true,
  },
  {
    title: "Esqueceu Senha",
    component: "EsqueceuSenha",
    path: "/esqueceu-senha",
    auth: false,
    exact: true,
  },
  {
    title: "Email Enviado",
    component: "EmailEnviado",
    path: "/email-enviado",
    auth: false,
    exact: true,
  },
  {
    title: "Configurações",
    component: "Configuracoes",
    path: "/configuracoes",
    auth: true,
    exact: true,
  },
  {
    title: "Redefinir Senha",
    component: "RedefinirSenha",
    path: "/redefinir-senha",
    auth: false,
    exact: true,
  },
  {
    title: "Criar Senha",
    component: "CriarSenha",
    path: "/criar-senha",
    auth: false,
    exact: true,
  },
  {
    title: "Completar Cadastro",
    component: "CompletarCadastro",
    path: "/completar-cadastro",
    auth: true,
    exact: true,
  },
  {
    title: "Editar Perfil",
    component: "EditarPerfil",
    path: "/editar-perfil",
    auth: true,
    exact: true,
  },
  {
    title: "Membros",
    component: "Membros",
    path: "/membros",
    auth: true,
    exact: true,
  },
  {
    title: "Estrutura",
    component: "Estrutura",
    path: "/estrutura",
    auth: true,
    exact: true,
  },
  {
    title: "Nova Estrutura",
    component: "NovaEstrutura",
    path: "/nova-estrutura",
    auth: true,
    exact: true,
  },
  {
    title: "Setores",
    component: "Setores",
    path: "/setores",
    auth: true,
    exact: true,
  },
  {
    title: "Organograma",
    component: "Organograma",
    path: "/organograma",
    auth: true,
    exact: true,
  },
  {
    title: "Cargos",
    component: "Cargos",
    path: "/cargos",
    auth: true,
    exact: true,
  },
  {
    title: "Procedimento",
    component: "Procedimento",
    path: "/procedimentos",
    auth: true,
    exact: true,
  },
  {
    title: "Meus Pops",
    component: "MeusPops",
    path: "/meuspops",
    auth: true,
    exact: true,
  },
  {
    title: "Processo",
    component: "Processos",
    path: "/processos",
    auth: true,
    exact: true,
  },
  {
    title: "Papéis",
    component: "Funcao",
    path: "/papeis",
    auth: true,
    exact: true,
  },
  {
    title: "Procedimento Continuar",
    component: "ProcedimentoContinue",
    path: "/procedimento/continuar/:id?",
    auth: true,
    exact: true,
  },
  {
    title: "Procedimento Detalhe",
    component: "ProcedimentoDetail",
    path: "/procedimento/:id",
    auth: true,
    exact: true,
  },
  {
    title: "Versao Procedimento Detalhe",
    component: "VersaoProcedimentoDetail",
    path: "/procedimento/versao/:id",
    auth: true,
    exact: true,
  },
  {
    title: "Processo Detalhe",
    component: "ProcessoDetail",
    path: "/processo/:id",
    auth: true,
    exact: true,
  },
  {
    title: "Membro Perfil",
    component: "MembroPerfil",
    path: "/membro/:id/perfil/:tab?",
    auth: true,
    exact: true,
  },
  {
    title: "Membro Editar Perfil",
    component: "EditarMembroPerfil",
    path: "/membro/:id/editar",
    auth: true,
    exact: true,
  },
  {
    title: "Editar Membros",
    component: "EditarMembros",
    path: "/membros/editar",
    auth: true,
    exact: true,
  },
  {
    title: "Confirmar Token",
    component: "ConfirmarToken",
    path: "/confirmar/:token",
    auth: false,
    exact: true,
  },
  {
    title: "Reset Token",
    component: "ResetToken",
    path: "/reset/:token",
    auth: false,
    exact: true,
  },
  {
    title: "Token Inválido",
    component: "TokenInvalido",
    path: "/invalido/",
    auth: false,
    exact: true,
  },
  {
    title: "Cadastro",
    component: "CadastroPorToken",
    path: "/cadastro/",
    auth: false,
    exact: true,
  },
  {
    title: "Resetar",
    component: "ResetarPorToken",
    path: "/resetar/",
    auth: false,
    exact: true,
  },
  {
    title: "Relatorio",
    component: "Relatorio",
    path: "/relatorio/:id",
    auth: true,
    exact: true,
  },
  {
    title: "Setor",
    component: "Setor",
    path: "/setor/:setorId?",
    auth: true,
    exact: true,
  },
  {
    title: "Rotina",
    component: "Rotina",
    path: "/rotina",
    auth: true,
    exact: true,
  },
  {
    title: "Calendário",
    component: "Calendario",
    path: "/calendario",
    auth: true,
    exact: true,
  },
  {
    title: "Listar registros de atividades",
    component: "ListarAtividades",
    path: "/listaratividades",
    auth: true,
    exact: true,
  },
  {
    title: "Dashboard",
    component: "Dashboard",
    path: "/dashboard/historico/conformidade",
    auth: false,
    exact: true,
  },
];

export default routes;
