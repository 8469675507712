import { all, takeLatest, call, put } from "redux-saga/effects";

import api from "../../../services/api";
import { SetorTypes, setSetor, addSetor, setOptionsSetores } from "./actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { logout } from "../auth/actions";
import { store } from "../../../store";

export function* get() {
  const response = yield call(api, "get", "setor");

  const { data, status } = response;

  const { role } = store.getState().auth.user;

  switch (status) {
    case 200:
      var optionsSetores = [];
      var optionsSetoresWithoutGrupo = [];
      var optionsFilterSetor = (role == 'Administrador' || role == 'Master_Admin') ? [{value: 'Todos', label: 'Todos', isFixed: true}] : [];
      if (data.length > 0) {
        data.map((setor) => {
          if (setor.grupo_id == null) {
            optionsSetoresWithoutGrupo.push({value: setor.id, label: setor.name, isFixed: true});
          }

          optionsSetores.push({value: setor.id, cor:setor.cor,label: setor.name, isFixed: true});
          optionsFilterSetor.push({value: setor.id, label: setor.name, isFixed: true});
        });
      }

      yield put(setSetor(data));
      yield put(setOptionsSetores(optionsSetores, optionsFilterSetor, optionsSetoresWithoutGrupo));
      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getById({ payload }) {
  const { id } = payload;
  const response = yield call(api, "get", `setor/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      var cargosOptions = [];
      if (data.cargos.length > 0) {
        data.cargos.map((cargo) => {
          cargosOptions.push({value: cargo.id, label: cargo.name, isFixed: true});
        });
      }
      yield put(addSetor(data, cargosOptions));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title:
            `Setor com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* create({ payload }) {
  const { setor, setModal } = payload;
  const response = yield call(api, "post", "setor", setor);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      setModal(false);
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 409:
      setModal(true);
      yield put(
        addToast({
          title: `Setor com nome ${setor.name} já cadastrado.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: 'Dados inválidos:',
          text: data,
          type: "warning",
        })
      );
    break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* update({ payload }) {
  const { id, setor,grupoId,setModal} = payload;
  const response = yield call(api, "put", `setor/${id}`, setor);

  const { data, status } = response;
  switch (status) {

    case 200:
    case 201:
      setModal(false);
              yield put(addToast({ title: 'Atualização realizada com sucesso!', type: 'success' }));

      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Setor com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 409:
      setModal(true);
      yield put(
        addToast({
          title: `Setor com nome ${setor.name} já cadastrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* deleteSetor({ payload }) {
  const { id } = payload;

  const response = yield call(api, "delete", `setor/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Setor excluído com sucesso",
          type: "success",
        })
      );
      break;

    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Setor com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(SetorTypes.GET, get),
  takeLatest(SetorTypes.GET_BY_ID, getById),
  takeLatest(SetorTypes.CREATE, create),
  takeLatest(SetorTypes.UPDATE, update),
  takeLatest(SetorTypes.DELETE, deleteSetor),
]);
