import produce from "immer";

import { ProcedimentoTypes } from "./actions";

export const INITIAL_STATE = {
  list: [],
  procedimentos: null,
  procedimento: null,
  procedimento_temporario: {
    name: "",
    funcao_id: { value: "", label: "", isFixed: true },
    processo_id: { value: "", label: "", isFixed: true },
    objetivo: "",
  },
  versao_procedimento: null,
  optionsProcedimentos: [],
  optionsProcedimentosWithoutEtapa: [],
  optionsVersoes: [],
  versaoSelected: null,
  status: null,
  atividade: null,
  statusAtividades: [],
  registroAtividade: {
    file: {},
    procedimento_id: "",
    status_id: "",
    uid: "",
  },
  registrosAtividades: [],
  novoProcedimento: null,
};

export default function procedimento(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ProcedimentoTypes.ADD: {
        draft.procedimentos = action.payload.procedimentos;
        break;
      }
      case ProcedimentoTypes.ADD_OPTIONS: {
        draft.optionsProcedimentos = action.payload.optionsProcedimentos;
        draft.optionsProcedimentosWithoutEtapa =
          action.payload.optionsProcedimentosWithoutEtapa;
        break;
      }
      case ProcedimentoTypes.ADD_PROCEDIMENTO: {
        draft.procedimento = action.payload.procedimento;
        break;
      }

      case ProcedimentoTypes.ADD_POP: {
        const { novoProcedimento } = action.payload;

        draft.procedimentos.unshift(novoProcedimento);

        if (action.payload.inverterOrdem) {
          draft.procedimentos.reverse();
        }
        break;
      }

      case ProcedimentoTypes.ADD_STATUS:
        draft.statusAtividades = action.payload.statusAtividades;
        break;

      case ProcedimentoTypes.REG_ATIVIDADE: {
        draft.registroAtividade = action.payload.registroAtividade;
        break;
      }

      case ProcedimentoTypes.ADD_REGISTROS_LISTADOS: {
        draft.registrosAtividades = action.payload.registrosAtividades;
        break;
      }

      case ProcedimentoTypes.SET_STATUS:
        return {
          ...state,
          status: action.payload,
        };
      case ProcedimentoTypes.ADD_PROCEDIMENTO_TEMPORARIO: {
        draft.procedimento_temporario = action.payload.procedimento_temporario;
        break;
      }
      case ProcedimentoTypes.ADD_VERSOES: {
        draft.optionsVersoes = action.payload.optionsVersoes;
        break;
      }
      case ProcedimentoTypes.ADD_VERSAO_PROCEDIMENTO: {
        draft.versao_procedimento = action.payload.versao_procedimento;
        break;
      }
      case ProcedimentoTypes.DELETE: {
        draft.procedimentos = draft.procedimentos.filter(
          item => item.id !== action.payload.id
        );
        break;
      }
      case ProcedimentoTypes.VERSAO_SELECTED: {
        draft.versaoSelected = action.payload.versaoSelected;
        break;
      }
      default:
    }
  });
}
