import React from "react";

export const SetorTypes = {
  GET: "@Setor/GET",
  GET_BY_ID: "@Setor/GET_BY_ID",
  ADD_SETOR: "@Setor/ADD_SETOR",
  SET: "@Setor/SET",
  SET_OPTIONS: "@Setor/SET_OPTIONS",
  CREATE: "@Setor/CREATE",
  UPDATE: "@Setor/UPDATE",
  DELETE: "@Setor/DELETE",
  STORE_ID: "@Setor/STORE_ID",
  SET_FILTER: "@Setor/SET_FILTER"
};

export function getSetor() {
  return {
    type: SetorTypes.GET,
  };
}

export function getById(id) {
  return {
    type: SetorTypes.GET_BY_ID,
    payload: {
      id,
    }
  };
}

export function addSetor(setor, cargosOptions) {
  return {
    type: SetorTypes.ADD_SETOR,
    payload: {
      setor, 
      cargosOptions
    }
  };
}

export function setSetor(data) {
  return {
    type: SetorTypes.SET,
    payload: {
      data,
    },
  };
}

export function setOptionsSetores(optionsSetores, optionsFilterSetor, optionsSetoresWithoutGrupo) {
  return {
    type: SetorTypes.SET_OPTIONS,
    payload: {
      optionsSetores,
      optionsFilterSetor,
      optionsSetoresWithoutGrupo
    },
  };
}

export function createSetor(setor, setModal) {
  return {
    type: SetorTypes.CREATE,
    payload: {
      setor,
      setModal
    },
  };
}

export function update(id, setor, setModal) {
  return {
    type: SetorTypes.UPDATE,
    payload: {
      id,
      setor,
      setModal
    },
  };
}

export function deleteSetor(id) {
  return {
    type: SetorTypes.DELETE,
    payload: {
      id
    },
  };
}

export function storeId(setor_id) {
  return {
    type: SetorTypes.STORE_ID,
    payload: {
      setor_id
    }
  };
}

export function setFilter(filter) {
  return {
    type: SetorTypes.SET_FILTER,
    payload: {
      filter
    }
  };
}
