import { all, takeLatest, call, put } from "redux-saga/effects";

import api from "../../../services/api";
import { PopTypes } from "./actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { logout } from "../auth/actions";

export function* create({ payload }) {
  const { popdata } = payload;
  const response = yield call(api, "post", "/pop", { data: popdata });

  const { data, status } = response;
  switch (status) {
    case 200:
      // yield put(dummyAction(data));

      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([takeLatest(PopTypes.CREATE, create)]);
