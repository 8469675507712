export const FuncaoTypes = {
  CREATE: "@Funcao/CREATE",
  UPDATE: "@Funcao/UPDATE",
  VINCULAR_SETOR: "@Funcao/VINCULAR_SETOR",
  GET: "@Funcao/GET",
  ADD: "@Funcao/ADD",
  GET_UNLIKED: "@Funcao/GET_UNLIKED",
  ADD_UNLIKED: "@Funcao/ADD_UNLIKED",
  ADD_FUNCAO: "@Funcao/ADD_FUNCAO",
  GET_BY_ID: "@Funcao/GET_BY_ID",
  GET_GROUPED: "@Funcao/GET_GROUPED",
  ADD_GROUPED: "@Funcao/ADD_GROUPED",
  GET_GROUPED_BY_CARGO: "@Funcao/GET_GROUPED_BY_CARGO",
  ADD_GROUPED_BY_CARGO: "@Funcao/ADD_GROUPED_BY_CARGO",
  DELETE: "@Funcao/DELETE"
};

export function get(search, filterStatus, filterExecucao, filterSetor, filterCargos, setLoading) {
  return {
    type: FuncaoTypes.GET,
    payload: {
      search,
      filterStatus, 
      filterExecucao,
      filterSetor, 
      filterCargos,
      setLoading
    },
  };
}

export function add(funcoes, funcoesOptions) {
  return {
    type: FuncaoTypes.ADD,
    payload: { 
      funcoes,
      funcoesOptions 
    },
  };
}

export function getUnliked() {
  return {
    type: FuncaoTypes.GET_UNLIKED,
    payload: {},
  };
}

export function addUnliked(optionsUnliked) {
  return {
    type: FuncaoTypes.ADD_UNLIKED,
    payload: { optionsUnliked },
  };
}

export function createFuncao(funcao, setModalFuncao) {
  return {
    type: FuncaoTypes.CREATE,
    payload: {
      funcao,
      setModalFuncao
    },
  };
}

export function updateFuncao(id, funcao, setModalFuncao) {
  return {
    type: FuncaoTypes.UPDATE,
    payload: {
      id,
      funcao,
      setModalFuncao
    },
  };
}

export function vincularSetor(funcao, id) {
  return {
    type: FuncaoTypes.VINCULAR_SETOR,
    payload: {
      funcao,
      id
    },
  };
}

export function getById(id) {
  return {
    type: FuncaoTypes.GET_BY_ID,
    payload: { id },
  };
}

export function addFuncao(funcao) {
  return {
    type: FuncaoTypes.ADD_FUNCAO,
    payload: { funcao },
  };
}

export function getGrouped() {
  return {
    type: FuncaoTypes.GET_GROUPED,
    payload: {},
  };
}

export function addFuncoesGrouped(funcoesAgrupadas, funcoesAgrupadasComSetor) {
  return {
    type: FuncaoTypes.ADD_GROUPED,
    payload: { 
      funcoesAgrupadas,
      funcoesAgrupadasComSetor
    },
  };
}

export function getGroupedByCargo(cargo_id) {
  return {
    type: FuncaoTypes.GET_GROUPED_BY_CARGO,
    payload: {
      cargo_id
    },
  };
}

export function addFuncoesGroupedByCargo(funcoesAgrupadasByCargo) {
  return {
    type: FuncaoTypes.ADD_GROUPED_BY_CARGO,
    payload: { 
      funcoesAgrupadasByCargo,
    },
  };
}

export function deleteFuncao(id) {
  return {
    type: FuncaoTypes.DELETE,
    payload: {
      id
    },
  };
}

