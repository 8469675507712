import produce from 'immer';

import { Grup, GrupoTypes } from './actions';

export const INITIAL_STATE = {
  grupos: null,
  optionsGrupos: [],
  grupoGeral: null
};

export default function grupo(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GrupoTypes.ADD: {
        draft.grupos = action.payload.grupos;
        draft.optionsGrupos = action.payload.optionsGrupos;
        draft.grupoGeral = action.payload.grupoGeral;
      }
      default:
    }
  });
}
