import { combineReducers } from "redux";

import toast from "./toast/reducer";
import auth from "./auth/reducer";
import member from "./member/reducer";
import setor from "./setor/reducer";
import cargo from "./cargo/reducer";
import breadcrumbs from "./breadcrumbs/reducer";
import funcao from "./funcao/reducer";
import procedimento from "./procedimento/reducer";
import processo from "./processo/reducer";
import grupo from "./grupo/reducer";
import etapa from "./etapa/reducer";
import organizacao from "./organizacao/reducer";
import setores from "./setores/reducer";
import grupos from "./grupos/reducer";
import cargos from "./cargos/reducer";

export default combineReducers({
  toast,
  member,
  cargo,
  setor,
  auth,
  breadcrumbs,
  procedimento,
  funcao,
  processo,
  grupo,
  etapa,
  organizacao,
  setores,
  grupos,
  cargos,
});
