import { all, takeLatest, call, put } from "redux-saga/effects";

import api from "../../../services/api";
import { GrupoTypes, add } from "./actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { logout } from "../auth/actions";

export function* get({ payload }) {
  const { setLoading } = payload;

  const response = yield call(api, "get", "grupos");

  const { data, status } = response;

  switch (status) {
    case 200:
      var optionsGrupos = [];
      var grupoGeral = [];
      data && data.map((grupo) => {
        if (grupo.name == 'Geral') {
          grupoGeral = grupo.id
        } 
        optionsGrupos.push({ value: grupo.id, label: grupo.name, isFixed: true });
      });

      yield put(add(data, optionsGrupos, grupoGeral));
      
      setLoading(false);

      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* create({ payload }) {
  const { grupo } = payload;
  const response = yield call(api, "post", "grupos", grupo);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      yield put(
        addToast({
            title: "Grupo criado com sucesso.",
            type: "success",
        })
      );
      history.go("/estrutura");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: `Grupo com nome ${grupo.name} já cadastrado.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: 'Dados inválidos:',
          text: data,
          type: "warning",
        })
      );
    break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* update({ payload }) {
  const { id, grupo } = payload;
  const response = yield call(api, "put", `grupos/${id}`, grupo);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      yield put(
        addToast({
            title: "Dados atualizados com sucesso.",
            type: "success",
        })
      );
      history.go("/estrutura");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Grupo com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: `Grupo com nome ${grupo.name} já cadastrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* deleteGrupo({ payload }) {
  const { id } = payload;
  const response = yield call(api, "delete", `grupos/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      yield put(
        addToast({
          title: "Grupo excluído com sucesso",
          type: "success",
        })
      );
      history.go("/estrutura");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Grupo com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(GrupoTypes.GET, get),
  takeLatest(GrupoTypes.CREATE, create),
  takeLatest(GrupoTypes.UPDATE, update),
  takeLatest(GrupoTypes.DELETE, deleteGrupo),
]);
