export const ProcedimentoTypes = {
  CREATE: "@Procedimento/CREATE",
  UPDATE: "@Procedimento/UPDATE",
  UPDATE_ONLY: "@Procedimento/UPDATE_ONLY",
  GET: "@Procedimento/GET",
  ADD: "@Procedimento/ADD",
  GET_ALL: "@Procedimento/GET_ALL",
  ADD_OPTIONS: "@Procedimento/ADD_OPTIONS",
  GET_BY_ID: "@Procedimento/GET_BY_ID",
  GET_BY_VERSAO_ID: "@Procedimento/GET_BY_VERSAO_ID",
  ADD_VERSOES: "@Procedimento/ADD_VERSOES",
  ADD_VERSAO_PROCEDIMENTO: "@Procedimento/ADD_VERSAO_PROCEDIMENTO",
  ADD_PROCEDIMENTO: "@Procedimento/ADD_PROCEDIMENTO",
  ADD_PROCEDIMENTO_TEMPORARIO: "@Procedimento/ADD_PROCEDIMENTO_TEMPORARIO",
  RESTORE: "@Procedimento/RESTORE",
  VERSAO_SELECTED: "@Procedimento/VERSAO_SELECTED",
  VINCULAR_PROCESSO: "@Procedimento/VINCULAR_PROCESSO",
  DESVINCULAR_PROCESSO: "@Procedimento/DESVINCULAR_PROCESSO",
  VINCULAR_ETAPA: "@Procedimento/VINCULAR_ETAPA",
  DELETE: "@Procedimento/DELETE",
  CHANGE_POSITION: "@Procedimento/CHANGE_POSITION",
  SET_STATUS: "@Procedimento/SET_STATUS",
  REG_ATIVIDADE: "@Procedimento/REG_ATIVIDADE",
  LISTAR_ATIVIDADE: "@Procedimento/LISTAR_ATIVIDADE",
  ADD_STATUS: "@Procedimento/ADD_STATUS",
  LISTAR_REGISTROS_ATIVIDADES: "@Procedimento/LISTAR_REGISTROS_ATIVIDADES",
  ADD_REGISTRO_ATIVIDADE: "@Procedimento/ADD_REGISTRO_ATIVIDADE",
  ADD_REGISTROS_LISTADOS: "@Procedimento/ADD_REGISTROS_LISTADOS",
  ADD_POP: "@Procedimento/ADD_POP",
  DELETE_SUCCESS: "@Procedimento/DELETE_SUCCESS",
};

export function get(
  search,
  filterStatus,
  filterExecucao,
  filterSetor,
  filterCargos,
  filterMember,
  filterTempo,
  setLoading
) {
  return {
    type: ProcedimentoTypes.GET,
    payload: {
      search,
      filterStatus,
      filterExecucao,
      filterSetor,
      filterCargos,
      filterMember,
      filterTempo,
      setLoading,
    },
  };
}

export function add(procedimentos) {
  return {
    type: ProcedimentoTypes.ADD,
    payload: { procedimentos },
  };
}

export function setStatus(status) {
  return {
    type: ProcedimentoTypes.SET_STATUS,
    payload: { status },
  };
}

export function addPop(novoProcedimento) {
  return {
    type: ProcedimentoTypes.ADD_POP,
    payload: { novoProcedimento },
  };
}

export function setRegistroAtividade(registroAtividade, setModal) {
  return {
    type: ProcedimentoTypes.REG_ATIVIDADE,
    payload: { registroAtividade },
  };
}

export function listarRegistroAtividades(id) {
  return {
    type: ProcedimentoTypes.LISTAR_REGISTROS_ATIVIDADES,
    payload: { id },
  };
}

export function adicionarRegistros(registrosAtividades) {
  return {
    type: ProcedimentoTypes.ADD_REGISTROS_LISTADOS,
    payload: { registrosAtividades },
  };
}

export function listarStatusAtividades() {
  return {
    type: ProcedimentoTypes.LISTAR_ATIVIDADE,
    payload: {},
  };
}
export function addStatus(statusAtividades) {
  return {
    type: ProcedimentoTypes.ADD_STATUS,
    payload: { statusAtividades },
  };
}

export function createProcedimento(procedimento, setModal, preCadastro) {
  return {
    type: ProcedimentoTypes.CREATE,
    payload: { procedimento, setModal, preCadastro },
  };
}

export function updateProcedimento(id, procedimento, url) {
  return {
    type: ProcedimentoTypes.UPDATE,
    payload: { id, procedimento, url },
  };
}

export function updateOnly(id, procedimento) {
  return {
    type: ProcedimentoTypes.UPDATE_ONLY,
    payload: { id, procedimento },
  };
}

export function getAll(a) {
  return {
    type: ProcedimentoTypes.GET_ALL,
    payload: {},
  };
}

export function addOptions(
  optionsProcedimentos,
  optionsProcedimentosWithoutEtapa
) {
  return {
    type: ProcedimentoTypes.ADD_OPTIONS,
    payload: {
      optionsProcedimentos,
      optionsProcedimentosWithoutEtapa,
    },
  };
}

export function getProcedimento(id, redirect = false) {
  return {
    type: ProcedimentoTypes.GET_BY_ID,
    payload: { id, redirect },
  };
}

export function addVersoes(optionsVersoes) {
  return {
    type: ProcedimentoTypes.ADD_VERSOES,
    payload: { optionsVersoes },
  };
}

export function addProcedimento(procedimento) {
  return {
    type: ProcedimentoTypes.ADD_PROCEDIMENTO,
    payload: { procedimento },
  };
}

export function addProcedimentoTemporario(procedimento_temporario) {
  return {
    type: ProcedimentoTypes.ADD_PROCEDIMENTO_TEMPORARIO,
    payload: { procedimento_temporario },
  };
}

export function getVersaoProcedimento(id) {
  return {
    type: ProcedimentoTypes.GET_BY_VERSAO_ID,
    payload: { id },
  };
}

export function addVersaoProcedimento(versao_procedimento) {
  return {
    type: ProcedimentoTypes.ADD_VERSAO_PROCEDIMENTO,
    payload: { versao_procedimento },
  };
}

export function restore(id) {
  return {
    type: ProcedimentoTypes.RESTORE,
    payload: { id },
  };
}

export function versaoSelected(versaoSelected) {
  return {
    type: ProcedimentoTypes.VERSAO_SELECTED,
    payload: { versaoSelected },
  };
}

export function vincularProcesso(id, informacoes) {
  return {
    type: ProcedimentoTypes.VINCULAR_PROCESSO,
    payload: {
      id,
      informacoes,
    },
  };
}

export function desvincularProcesso(id) {
  return {
    type: ProcedimentoTypes.DESVINCULAR_PROCESSO,
    payload: { id },
  };
}

export function deleteProcedimento(id) {
  return {
    type: ProcedimentoTypes.DELETE,
    payload: {
      id,
    },
  };
}

export function deletePopSuccess(id) {
  return {
    type: ProcedimentoTypes.DELETE_SUCCESS,
    payload: {
      id: id,
    },
  };
}

export function vincularEtapa(dados) {
  return {
    type: ProcedimentoTypes.VINCULAR_ETAPA,
    payload: {
      dados,
    },
  };
}

export function changePosition(dados) {
  return {
    type: ProcedimentoTypes.CHANGE_POSITION,
    payload: {
      dados,
    },
  };
}
