import { takeLatest, call, put, all } from "redux-saga/effects";

import api from "../../../services/api";
import {
  MemberType,
  setMember,
  setList,
  setOptionsMember,
  setMemberProfile,
  getAll as getAllMembers,
  setUsers,
  statusMember,
} from "./actions";
import { logout } from "../auth/actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { store } from "../../../store";
import { getTree } from "../cargo/actions";
import { getSetor } from "../setor/actions"

export function* getAll() {
  const response = yield call(api, "get", `/member`);

  const { data, status } = response;
  console.log(response)
  switch (status) {
    case 200:
      yield put(setList(data.data));
      yield put(statusMember(status));
      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
  return;
}

export function* get() {
  const response = yield call(api, "get", `/members`);

  const { data, status } = response;
  switch (status) {
    case 200:
      const optionsMember = [];

      optionsMember.push({
        value: null,
        label: "Todos",
        isFixed: true,
      });

      data.map(item => {
        optionsMember.push({
          value: item.id,
          label: `${item.name} ${item.last_name}`,
          isFixed: true,
        });
      });

      yield put(setOptionsMember(optionsMember));
      yield put(setUsers(data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
  return;
}

export function* getAllOrder({ payload }) {
  const { orderBy } = payload;
  const response = yield call(
    api,
    "get",
    `/member-query?order=${orderBy}&by=last_access`
  );

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(setList(data.data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
  return;
}

export function* find({ payload }) {
  const response = yield call(api, "get", `member/${payload.id}`);
  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(setMember(data.data.user, data.data.user.foto_perfil || null));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* findProfile({ payload }) {
  const response = yield call(api, "get", `member/${payload.id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(setMemberProfile(data.data.user));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* updatePhoto({ payload }) {
  const { id } = store.getState().auth.user;

  const formData = new FormData();
  formData.append("foto_perfil", payload.photo.file);

  const response = yield call(api, "post", `/member/${id}`, formData);

  const { data, status } = response;
  switch (status) {
    case 200:
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* createWithAccess({ payload }) {
  const { new_user } = payload;

  const response = yield call(api, "post", `/member/accessible`, new_user);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: "Usuário já cadastrado nesta organização.",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* createWithNoAccess({ payload }) {
  const { new_user } = payload;

  const response = yield call(api, "post", `/member/not-accessible`, new_user);

  const { data, status } = response;
  switch (status) {
    case 200:
    case 201:
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 409:
      yield put(
        addToast({
          title: "Usuário já cadastrado nesta organização.",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* updateMember({ payload }) {
  const { id, member } = payload;

  const response = yield call(api, "post", `/member/${id}`, member);
  const { data, status } = response;
  switch (status) {
    case 200:
      history.push("/membros");
      yield put(
        addToast({
          type: "success",
          title: "Dados atualizados com sucesso.",
        })
      );
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* updateMemberProfile({ payload }) {
  const { id, dados } = payload;

  const response = yield call(api, "post", `/member/${id}`, dados);

  const { data, status } = response;
  switch (status) {
    case 200:
      history.push(`/membro/${id}/perfil`);
      yield put(
        addToast({
          type: "success",
          title: "Dados atualizados com sucesso.",
        })
      );
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* updateMemberPhoto({ payload }) {
  const { id, formData } = payload;

  const response = yield call(api, "post", `/member/${id}`, formData);

  const { data, status } = response;
  switch (status) {
    case 200:
      history.push("/membros");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* invite({ payload }) {
  const { id } = payload;

  const response = yield call(api, "post", `/member/invite/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* switchUserStatus({ payload }) {
  const { id } = payload;

  const response = yield call(api, "put", `/member/status/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    case 422:
      yield put(
        addToast({
          title: "Usuário não encontrado",
          type: "danger",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* deleteMember({ payload }) {
  const { id } = payload;

  const response = yield call(api, "delete", `/member/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      history.go("/membros");
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    case 422:
      yield put(
        addToast({
          title: "Usuário não encontrado",
          type: "danger",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* vincularCargo({ payload }) {
  const { dados } = payload;

  const response = yield call(api, "put", `/members/`, dados);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(getTree());
      yield put(getSetor());
      yield put(getAllMembers());
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(MemberType.GET, get),
  takeLatest(MemberType.ALL, getAll),
  takeLatest(MemberType.FIND, find),
  takeLatest(MemberType.FIND_PROFILE, findProfile),
  takeLatest(MemberType.PHOTO, updatePhoto),
  takeLatest(MemberType.GET_ALL_ORDER, getAllOrder),
  takeLatest(MemberType.CREATE_ACCESS, createWithAccess),
  takeLatest(MemberType.CREATE_NOT_ACCESS, createWithNoAccess),
  takeLatest(MemberType.UPDATE_MEMBER, updateMember),
  takeLatest(MemberType.UPDATE_PROFILE, updateMemberProfile),
  takeLatest(MemberType.UPDATE_MEMBER_PHOTO, updateMemberPhoto),
  takeLatest(MemberType.INVITE, invite),
  takeLatest(MemberType.SWITCH_USER_STATUS, switchUserStatus),
  takeLatest(MemberType.DELETE, deleteMember),
  takeLatest(MemberType.VINCULAR_CARGO, vincularCargo),
]);
