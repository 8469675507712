import { all, takeLatest, call, put } from "redux-saga/effects";
import api from "../../../services/api";
import { SetoresTypes, addSetor, listarSetores,setSetoresTeste } from "./actions";
import { addToast } from "../toast/actions";
import { logout } from "../auth/actions";
import { store } from "../../../store";


export function* get() {
  try {
    const response = yield call(api, "get", "/v2/setores");
    const { data, status } = response;
    const { role } = store.getState().auth.user;
    

    switch (status) {
      case 200:
        var listar= [];

        if (data.length > 0) {
          data.map((setor) => {
            if (setor) {
              listar.push(setor);
            }
          });
        }
        yield put(setSetoresTeste(data))
     
        break;
      case 401:
        yield put(
          addToast({
            title: "Sessão Inválida",
            type: "warning",
          })
        );
        break;
      case 402:
        yield put(logout());
        yield put(
          addToast({
            title: "Sessão Expirada",
            type: "warning",
          })
        );
        break;
      case 423:
        yield put(
          addToast({
            title: "Usuário inativo.",
            type: "danger",
          })
        );
        yield put(logout());
        break;
      default:
        yield put(
          addToast({
            title:
              "Algo deu errado, tente novamente ou entre em contato com o suporte.",
            type: "danger",
          })
        );
    }
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
}

export function* getById({ payload }) {
  const { id } = payload;
  const response = yield call(api, "get", `v2/setores/${id}`);
  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(addSetor(data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title:
            `Setor com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
    takeLatest(SetoresTypes.GET, get),
    takeLatest(SetoresTypes.GET_BY_ID, getById),
]);
