import produce from 'immer';

import { GruposTypes } from './actions';

export const INITIAL_STATE  ={
    listarGrupos:[],
    grupos:null
};

export default function grupos(state= INITIAL_STATE, action){
    return produce(state, draft => {
        
        switch(action.type){
            case GruposTypes.SET: {
                draft.listarGrupos = action.payload.data;
                break;
              }
            default:
        }
    })
}