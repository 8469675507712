import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  
  :root{
    --primary-color: #11998e;  
    --gray-default: #f3f7f9;
    --gray-hover-default: #e5eaec;
    --green:#EFFDFA;
    --cor-borda: #e4eaec;
    --cor-texto:#76838f;
    --cor-titulo: #37474f;
    --cor-subtitulo:#526069;

    --gray-800: #263238;
    --gray-700: #37474F;
    --gray-600: #526069;
    --gray-500:  #76838F;
    --gray-400:  #A3AFB7;
    --gray-300:  #CCD5DB;
    --gray-200:  #E4EAEC;
    --gray-100:  #F3F7F9;
  }

  body {
    padding-top: 0px;
    background: var(--gray-100) !important;
    color: #3A525A;
    -webkit-font-smoothing: antialiased;
   
  }


.primary{
      color:var(--primary-color);
      border:1px solid var(--primary-color);
      &:hover{
        color:white;
        background-color: var(--primary-color);
      }
}

  body, input, textarea, select, button, a {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  .rodape-footer {
    height: 44px;
    padding: 10px 30px;
    bottom: 0px;
    background-color: white;
    border-top: 1px solid #e4eaec;
    z-index: 2;
    bottom:0;
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin:0 !important;

  }

  .site-footer-legal {
    color:#76838f; 
    float:none !important;
    display:flex;
    justify-content:center;
    @media(min-width:600px){
      margin-left:260px;
    }
    a{
      color:#11996a;
    }
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }

  strong {
    font-weight: 700;
  }

  button {
    cursor: pointer;
  }

  select {
    background: white;
  }

  .nav-item {
    cursor: pointer;
  }

  .clickable {
    cursor: pointer;
  }

  .panel-padding {
    background: #F3F7F9;
    padding: 0px 25px;

    @media (max-width: 500px) {
      padding: 10px;
    }

    margin-bottom: calc(52vh - 60px);
  }

  .container-fluid {
    margin-bottom: calc(50vh - 60px);
  }

  .default-title{
    color: #37474f;
    font-size: 18px;
    line-height: 21px;

  }

  .default-subtitle{
    color: #76838f;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }

  .modal-header{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    border-bottom: 1px solid #dee2e6 !important;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }

  .modal-title{
    margin-bottom: 0 !important;
    font-size:1.25rem !important;
  }

.modal-header .close{
  padding: 15px !important;
  margin:0 !important;
}
`;
