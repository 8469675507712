import React from "react";

import './style.scss';

export default function PageContent({ children }) {
  return (
    <div>
      <div>{children}
      </div>
    </div>
    
  );
}
