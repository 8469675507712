import produce from "immer";

import { FuncaoTypes } from "./actions";

export const INITIAL_STATE = {
  list: [],
  funcoes: null,
  funcao: null,
  funcoesOptions: [],
  funcoesAgrupadas: [],
  funcoesAgrupadasByCargo: [],
  funcoesAgrupadasComSetor: [],
  optionsUnliked: [],
};

export default function funcao(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case FuncaoTypes.ADD: {
        draft.funcoes = action.payload.funcoes;
        draft.funcoesOptions = action.payload.funcoesOptions;
        break;
      }
      case FuncaoTypes.ADD_UNLIKED: {
        draft.optionsUnliked = action.payload.optionsUnliked;
        break;
      }
      case FuncaoTypes.ADD_FUNCAO: {
        draft.funcao = action.payload.funcao;
        break;
      }
      case FuncaoTypes.ADD_GROUPED: {
        draft.funcoesAgrupadas = action.payload.funcoesAgrupadas;
        draft.funcoesAgrupadasComSetor = action.payload.funcoesAgrupadasComSetor;
        break;
      }
      case FuncaoTypes.ADD_GROUPED_BY_CARGO: {
        draft.funcoesAgrupadasByCargo = action.payload.funcoesAgrupadasByCargo;
        break;
      }
      default:
    }
  });
}
