import produce from 'immer';

import { SetorTypes } from './actions';

export const INITIAL_STATE = {
  list: [],
  cargosOptions: [],
  optionsSetores: [],
  optionsFilterSetor: [],
  setor: null,
  setor_id: null,
  filter: null
};

export default function setor(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SetorTypes.SET: {
        draft.list = action.payload.data;
        break;
      }
      case SetorTypes.SET_OPTIONS: {
        draft.optionsSetores = action.payload.optionsSetores;
        draft.optionsFilterSetor = action.payload.optionsFilterSetor;
        draft.optionsSetoresWithoutGrupo = action.payload.optionsSetoresWithoutGrupo;
        break;
      }
      case SetorTypes.ADD_SETOR: {
        draft.cargosOptions = action.payload.cargosOptions;
        draft.setor = action.payload.setor;
      }
      case SetorTypes.STORE_ID: {
        draft.setor_id = action.payload.setor_id;
      }
      case SetorTypes.SET_FILTER: {
        draft.filter = action.payload.filter;
      }
      default:
    }
  });
}
