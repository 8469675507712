export const ProcessosTypes = {
    GET: "@Processo/GET",
    ADD: "@Processo/ADD",
    GET_OPTIONS: "@Processo/GET_OPTIONS",
    ADD_OPTIONS: "@Processo/ADD_OPTIONS",
    GET_BY_ID: "@Processo/GET_BY_ID",
    ADD_PROCESSO: "@Processo/ADD_PROCESSO",
    CREATE: "@Processo/CREATE",
    UPDATE: "@Processo/UPDATE",
    DELETE: "@Processo/DELETE"
};

export function get(search, filterStatus, filterExecucao, filterSetor, filterCargos, setLoading) {
  return {
    type: ProcessosTypes.GET,
    payload: {
      search,
      filterStatus, 
      filterExecucao,
      filterSetor, 
      filterCargos,
      setLoading
    },
  };
}

export function add(processos) {
  return {
    type: ProcessosTypes.ADD,
    payload: { processos },
  };
}

export function getOptions() {
  return {
    type: ProcessosTypes.GET_OPTIONS,
    payload: {},
  };
}

export function addOptions(optionsProcessos) {
  return {
    type: ProcessosTypes.ADD_OPTIONS,
    payload: { optionsProcessos },
  };
}

export function create(processo, setModalProcesso) {
    return {
      type: ProcessosTypes.CREATE,
      payload: {
        processo,
        setModalProcesso
      },
    };
}
  
export function update(id, processo, setModalProcesso) {
    return {
      type: ProcessosTypes.UPDATE,
      payload: {
        id,
        processo,
        setModalProcesso
      },
    };
}

export function getById(id) {
    return {
        type: ProcessosTypes.GET_BY_ID,
        payload: { id },
    };
}
  
export function addProcesso(processo) {
    return {
        type: ProcessosTypes.ADD_PROCESSO,
        payload: { processo },
    };
}

export function deleteProcesso(id) {
  return {
    type: ProcessosTypes.DELETE,
    payload: {
      id
    },
  };
}