import { all } from "redux-saga/effects";

// import dummy from './dummy/sagas';
import member from "./member/sagas";
import auth from "./auth/sagas";
import cargo from "./cargo/sagas";
import setor from "./setor/sagas";
import pop from "./pop/sagas";
import funcao from "./funcao/sagas";
import procedimento from "./procedimento/sagas";
import processo from "./processo/sagas";
import grupo from "./grupo/sagas";
import etapa from "./etapa/sagas";
import organizacao from "./organizacao/sagas";
import setores from "./setores/sagas";
import grupos from "./grupos/sagas";
import cargos from "./cargos/sagas";

export default function* rootSaga() {
  return yield all([auth, member, cargo, setor, funcao, pop, procedimento, processo, grupo, etapa, organizacao,setores,grupos,cargos]);
}
