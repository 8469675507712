export const EtapaTypes = {
    GET: "@Etapa/GET",
    CREATE: "@Etapa/CREATE",
    ADD: "@Etapa/ADD",
    UPDATE: "@Etapa/UPDATE",
    DELETE: "@Etapa/DELETE",
    CHANGE_POSITION: "@Etapa/CHANGE_POSITION"
  };
  
export function get() {
    return {
        type: EtapaTypes.GET,
    };
}
  
export function create(etapa) {
    return {
        type: EtapaTypes.CREATE,
        payload: {
            etapa
        },
    };
}

export function add(etapas) {
    return {
        type: EtapaTypes.ADD,
        payload: {
            etapas
        }
    };
}

export function update(id, etapa) {
    return {
        type: EtapaTypes.UPDATE,
        payload: {
            id,
            etapa,
        },
    };
}

export function deleteEtapa(id) {
    return {
        type: EtapaTypes.DELETE,
        payload: {
            id
        },
    };
}

export function changePosition(dados) {
    return {
      type: EtapaTypes.CHANGE_POSITION, 
      payload: {
        dados
      },
    }
  }