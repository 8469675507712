export const OrganizacaoTypes = {
    GET: "@Organizacao/GET",
    ADD: "@Organizacao/ADD",
    GET_ORG_BY_MEMBER: "@Organizacao/GET_ORG_BY_MEMBER",
    GET_BY_ID: "@Organizacao/GET_BY_ID",
    ADD_BY_ID: "@Organizacao/ADD_BY_ID",
    CREATE: "@Organizacao/CREATE",
    UPDATE: "@Organizacao/UPDATE",
    DELETE: "@Organizacao/DELETE",
    SEND_EMAIL: "@Organizacao/SEND_EMAIL"
};

export function get(search, filterStatus, filterCategoria, setLoading) {
    return {
        type: OrganizacaoTypes.GET,
        payload: {
            search,
            filterStatus,
            filterCategoria,
            setLoading
        }
    };
}

export function add(organizacoes) {
    return {
        type: OrganizacaoTypes.ADD,
        payload: {
            organizacoes
        }
    };
}

export function getOrgByMember(member_id) {
    return {
        type: OrganizacaoTypes.GET_ORG_BY_MEMBER,
        payload: {
            member_id
        }
    }
}

export function getById(id) {
    return {
        type: OrganizacaoTypes.GET_BY_ID,
        payload: {
            id,
        }
    };
}

export function addById(organizacao) {
    return {
        type: OrganizacaoTypes.ADD_BY_ID,
        payload: {
            organizacao
        }
    };
}

export function create(organizacao, id) {
    return {
        type: OrganizacaoTypes.CREATE,
        payload: {
            organizacao,
            id
        },
    };
}


export function update(id, organizacao) {
    return {
        type: OrganizacaoTypes.UPDATE,
        payload: {
            id,
            organizacao,
        },
    };
}

export function deleteOrganizacao(id) {
    return {
        type: OrganizacaoTypes.DELETE,
        payload: {
            id
        },
    };
}

export function sendEmail(organizacao_id)
{
    return {
        type: OrganizacaoTypes.SEND_EMAIL,
        payload: {
            organizacao_id
        }
    }
}