import { all, takeLatest, call, put } from "redux-saga/effects";
import api from "../../../services/api";
import { GruposTypes, setStatus,setGrupos} from "./actions";
import { addToast } from "../toast/actions";
import { logout } from "../auth/actions";
import { store } from "../..";


export function* get() {
  try {
    const response = yield call(api, "get", "/v2/grupos");
    const { data, status } = response;
    switch (status) {
      case 200:
        var listar= [];

        if (data.length > 0) {
          data.map((grupo) => {
            if (grupo) {
              listar.push(grupo);
            }
          });
        }
        yield put(setGrupos(data))
        yield put(setStatus(status))
     
        break;
      case 401:
        yield put(
          addToast({
            title: "Sessão Inválida",
            type: "warning",
          })
        );
        break;
      case 402:
        yield put(logout());
        yield put(
          addToast({
            title: "Sessão Expirada",
            type: "warning",
          })
        );
        break;
      case 423:
        yield put(
          addToast({
            title: "Usuário inativo.",
            type: "danger",
          })
        );
        yield put(logout());
        break;
      default:
        yield put(
          addToast({
            title:
              "Algo deu errado, tente novamente ou entre em contato com o suporte.",
            type: "danger",
          })
        );
    }
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
}

export default all([
    takeLatest(GruposTypes.GET, get),
]);
