import { all, takeLatest, call, put } from "redux-saga/effects";

import api from "../../../services/api";
import {
  CargoTypes,
  setCargo,
  setTree,
  getTree as getTreeAction,
  getCargo,
  addChildren,
  addParent,
  setStatus,
  setCargoSucesso,
} from "./actions";
import { getSetor } from "../setor/actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { logout } from "../auth/actions";
import { listarCargos } from "../cargos/actions";

export function* get() {
  const response = yield call(api, "get", "cargo");

  const { data, status } = response;

  switch (status) {
    case 200:
      var optionsCargos = [];
      var optionsFilterCargo = [{ value: null, label: "Todos", isFixed: true , users:[]}];
      data &&
        data.map(cargo => {
          optionsCargos.push({
            value: cargo.id,
            label: cargo.name,
            isFixed: true,
            is_cargo_org: cargo.is_cargo_org,
            setor_id: cargo.setor_id,
            users:cargo.users
          });
          optionsFilterCargo.push({
            value: cargo.id,
            label: cargo.name,
            isFixed: true,
            is_cargo_org: cargo.is_cargo_org,
            setor_id: cargo.setor_id,
            users:cargo.users
          });
        });

      yield put(setCargo(data, optionsCargos, optionsFilterCargo));
      yield put(setStatus(status))
      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getTree() {
  const response = yield call(api, "get", "cargo-tree");

  const { data, status } = response;

  switch (status) {
    case 200:
      
      yield put(setTree(data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getChildren({ payload }) {
  const { id } = payload;

  const response = yield call(api, "get", `cargo-children/${id}`);

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(addChildren(data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Cargo não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getParent({ payload }) {
  const { id } = payload;
  const response = yield call(api, "get", `cargo-parent/${id}`);

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(addParent(data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Cargo não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* create({ payload }) {
  const { cargo, setCreateCargoModal } = payload;
  const response = yield call(api, "post", "cargo", cargo);
  const { data, status } = response;
 
  switch (status) {
    case 200:
      setCreateCargoModal(false);
      yield put(getCargo());
      yield put(getSetor());
      yield put(getTreeAction());
      yield put(setCargoSucesso(data.id));
      break;
    case 201:
      setCreateCargoModal(false);
      yield put(getCargo());
      yield put(getTreeAction());
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 409:
      setCreateCargoModal(true);
      yield put(
        addToast({
          title: `Cargo com nome ${cargo.name} já cadastrado.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: "Dados inválidos:",
          text: data,
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* update({ payload }) {
  const { cargo, setCreateCargoModal } = payload;
  const response = yield call(api, "put", `cargo/${cargo.id}`, cargo);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: "Cargo atualizado com sucesso!",
          type: "success",
        })
      );
      yield put(listarCargos());
      yield put(getCargo());
      break;
    case 201:
      setCreateCargoModal(false);
      history.push("/estrutura");
      yield put(listarCargos());
      yield put(getCargo());
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* deleteCargo({ payload }) {
  const { id } = payload;
  const response = yield call(api, "delete", `cargo/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(
        addToast({
          title: `Papel apagado com sucesso.`,
          type: "success",
        })
      );
      history.push("/estrutura");
      yield put(getCargo());
      yield put(getSetor());
      yield put(getTreeAction());
    case 201:
      history.push("/estrutura");
      yield put(getTreeAction());
      break;
    case 404:
      yield put(
        addToast({
          title: `Cargo não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(CargoTypes.GET, get),
  takeLatest(CargoTypes.GET_TREE, getTree),
  takeLatest(CargoTypes.GET_CHILDREN, getChildren),
  takeLatest(CargoTypes.GET_PARENT, getParent),
  takeLatest(CargoTypes.CREATE, create),
  takeLatest(CargoTypes.UPDATE, update),
  takeLatest(CargoTypes.DELETE, deleteCargo),
  
]);
