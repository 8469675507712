import { all, takeLatest, call, put } from "redux-saga/effects";
import api from "../../../services/api";
import { CargosTypes,setCargos, addChildren,setStatus } from "./actions";
import { addToast } from "../toast/actions";
import { logout } from "../auth/actions";
import { store } from "../..";


export function* get() {
  try {
    const response = yield call(api, "get", "/v2/cargos");
    const { data, status } = response;

    switch (status) {
      case 200:
        yield put(setCargos(data))
        yield put(setStatus(status))
     
        break;
      case 401:
        // yield put(logout());
        yield put(
          addToast({
            title: "Sessão Inválida",
            type: "warning",
          })
        );
        break;
      case 402:
        // yield put(logout());
        yield put(
          addToast({
            title: "Sessão Expirada",
            type: "warning",
          })
        );
        break;
      case 423:
        yield put(
          addToast({
            title: "Usuário inativo.",
            type: "danger",
          })
        );
        yield put(logout());
        break;
      default:
        yield put(
          addToast({
            title:
              "Algo deu errado, tente novamente ou entre em contato com o suporte.",
            type: "danger",
          })
        );
    }
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
}
export function* getChildren() {
  try {
    const response = yield call(api, "get", "/v2/cargos");
    const { data, status } = response;
    const { role } = store.getState().auth.user;

    switch (status) {
      case 200:
        yield put(addChildren(data))
     
        break;
      case 401:
        yield put(logout());
        yield put(
          addToast({
            title: "Sessão Inválida",
            type: "warning",
          })
        );
        break;
      case 402:
        yield put(logout());
        yield put(
          addToast({
            title: "Sessão Expirada",
            type: "warning",
          })
        );
        break;
      case 423:
        yield put(
          addToast({
            title: "Usuário inativo.",
            type: "danger",
          })
        );
        yield put(logout());
        break;
      default:
        yield put(
          addToast({
            title:
              "Algo deu errado, tente novamente ou entre em contato com o suporte.",
            type: "danger",
          })
        );
    }
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
}

export default all([
    takeLatest(CargosTypes.GET, get),
    takeLatest(CargosTypes.GET_CHILDREN, getChildren),
]);
