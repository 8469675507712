export const PopTypes = {
  GET: '@POP/GET',
  SET: '@POP/SET',
  CREATE: '@POP/CREATE',
};

export function get() {
  return {
    type: PopTypes.GET,
  };
}

export function create(data) {
  return {
    type: PopTypes.CREATE,
    payload: {
      popdata: data,
    }
  };
}

