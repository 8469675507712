import produce from 'immer';

import { SetoresTypes } from './actions';

export const INITIAL_STATE  ={
    listarSetores:[],
    cargosOptions: [],
    StylePropertyMap:null,
    setorAtual: null,
    setores:null,
};

export default function setores(state= INITIAL_STATE, action){
    return produce(state, draft => {
        
        switch(action.type){
            case SetoresTypes.SET: {
                draft.listarSetores = action.payload.data;
                break;
              }
              case SetoresTypes.ADD_SETOR: {
                draft.setores = action.payload.setores;
                break;
              }

              case SetoresTypes.GET_BY_ID: {
                draft.setorAtual = action.payload.setores;
                break;
            }
            default:
        }
    })
}
