import produce from "immer";

import { CargoTypes } from "./actions";

export const INITIAL_STATE = {
  list: [],
  tree: null,
  optionsCargos: [],
  optionsFilterCargo: [],
  parent: null,
  children: null,
  status: null,
  cargoSucesso: null,
};

export default function cargo(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case CargoTypes.SET: {
        draft.list = action.payload.data;
        draft.optionsCargos = action.payload.optionsCargos;
        draft.optionsFilterCargo = action.payload.optionsFilterCargo;
        break;
      }
      case CargoTypes.SET_TREE: {
        draft.tree = action.payload.tree;
        break;
      }
      case CargoTypes.ADD_PARENT: {
        draft.parent = action.payload.data;
        break;
      }
      case CargoTypes.ADD_CHILDREN: {
        draft.children = action.payload.data;
        break;
      }
      case CargoTypes.SET_CARGO_SUCESS: {
        draft.cargoSucesso = action.payload.cargoSucesso;
        break;
      }

      case CargoTypes.SET_STATUS:
        return {
          ...state,
          status: action.payload,
        };
      default:
    }
  });
}
