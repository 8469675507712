import { all, takeLatest, call, put, delay } from "redux-saga/effects";

import api from "../../../services/api";
import { 
  ProcessosTypes, 
  add, 
  addProcesso,
  get as getAllProcessos,
  getOptions,
  addOptions
} from "./actions";
import { addToast } from "../../../store/modules/toast/actions";
import history from "../../../services/history";
import { logout } from "../auth/actions";

export function* create({ payload }) {
  const { processo, setModalProcesso } = payload;
  const response = yield call(api, "post", "processos", processo);

  const { data, status } = response;
  switch (status) {
    case 200:
      setModalProcesso(false);
      yield put(
        addToast({
          title: "Processo cadastrado",
          type: "success",
        })
      );
      yield put(getAllProcessos());
      yield put(getOptions());
      break;
    case 201:
      setModalProcesso(false);
      yield put(
        addToast({
          title: "Processo cadastrado",
          type: "success",
        })
      );
      yield put(getAllProcessos());
      break;
    case 401:
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 409:
      setModalProcesso(true);
      yield put(
        addToast({
          title: `Processo com título ${processo.title} já cadastrado.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: 'Dados inválidos:',
          text: data,
          type: "warning",
        })
      );
    break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* update({ payload }) {
  const { id, processo, setModalProcesso } = payload;
  const response = yield call(api, "put", `processos/${id}`, processo);

  const { data, status } = response;
  switch (status) {
    case 200:
      setModalProcesso(false);
      yield put(
        addToast({
          title: "Processo atualizado",
          type: "success",
        })
      );
      yield put(getAllProcessos());
      yield put(getOptions());
      break;
    case 201:
      setModalProcesso(false);
      yield put(
        addToast({
          title: "Processo atualizado",
          type: "success",
        })
      );
      yield put(getAllProcessos());
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
    yield put(
      addToast({
        title: `Processo com id ${id} não encontrado.`,
        type: "warning",
      })
    );
    break;
    case 409:
      setModalProcesso(true);
      yield put(
        addToast({
          title: `Processo com título ${processo.title} já cadastrado.`,
          type: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          title: 'Dados inválidos:',
          text: data,
          type: "warning",
        })
      );
    break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* get({ payload }) {
  yield delay(50);

  const { search, filterStatus, filterExecucao, filterSetor, filterCargos, setLoading } = payload;

  const response = yield call(
    api, 
    'get', 
    '/processos', 
    {}, 
    {
      search, filterStatus, filterExecucao, filterSetor, filterCargos
    }
  );

  console.log(payload)
  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(add(data));
      
      if (setLoading !== undefined) { 
        setLoading(false);
      } 
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getAll() {
  yield delay(50);

  const response = yield call(api, 'get', '/all-processos', {}, {});
  const { data, status } = response;
  console.log(response)
  switch (status) {
    case 200:
      // eslint-disable-next-line no-case-declarations
      var optionsProcessos = [];
      data && data.map((processo) => {
        optionsProcessos.push({ value: processo.id, label: processo.title, isFixed: true, setor_id: processo.setor_id });
      });

      yield put(addOptions(optionsProcessos));
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* getById({ payload }) {
  yield delay(50);

  const { id } = payload;
  const response = yield call(api, 'get', `/processos/${id}`, {}, {});
  const { data, status } = response;
  
  switch (status) {
    case 200:
      // eslint-disable-next-line no-case-declarations
      yield put(addProcesso(data));
      break;
    case 401:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Inválida",
          type: "warning",
        })
      );
      break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          title: `Processo com id ${id} não encontrado.`,
          type: "warning",
        })
      );
      break;
    case 423:
      yield put(
        addToast({
          title: "Usuário inativo.",
          type: "danger",
        })
      );
      yield put(logout());
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export function* deleteProcesso({ payload }) {
  const { id } = payload;
  const response = yield call(api, "delete", `processos/${id}`);

  const { data, status } = response;
  switch (status) {
    case 200:
      history.go("/procedimentos");
    case 201:
      history.go("/procedimentos");
      break;
    case 404:
      yield put(
        addToast({
          title: `Processo não encontrado.`,
          type: "warning",
        })
      );
    break;
    case 402:
      yield put(logout());
      yield put(
        addToast({
          title: "Sessão Expirada",
          type: "warning",
        })
      );
      break;
    default:
      yield put(
        addToast({
          title:
            "Algo deu errado, tente novamente ou entre em contato com o suporte.",
          type: "danger",
        })
      );
  }
}

export default all([
  takeLatest(ProcessosTypes.GET, get),
  takeLatest(ProcessosTypes.GET_OPTIONS, getAll),
  takeLatest(ProcessosTypes.GET_BY_ID, getById),
  takeLatest(ProcessosTypes.CREATE, create),
  takeLatest(ProcessosTypes.UPDATE, update),
  takeLatest(ProcessosTypes.DELETE, deleteProcesso),
]);
