import React from "react";

export const SetoresTypes = {
    GET: "@Setores/GET",
    GET_BY_ID: "@Setores/GET_BY_ID",
    ADD_SETOR: "@Setores/ADD_SETOR",
    SET: "@Setores/SET",
};


export function listarSetores(){
    return {
        type: SetoresTypes.GET

    }
}

export function setSetoresTeste(data) {
    return {
      type: SetoresTypes.SET,
      payload: {
        data,
      },
    };
  }

  export function addSetor(setores) {
    return {
      type: SetoresTypes.ADD_SETOR,
      payload: {
        setores,
      }
    };
  }

  export function getById(id) {
    return {
      type: SetoresTypes.GET_BY_ID,
      payload: {
        id,
      }
    };
  }
  