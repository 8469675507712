import produce from "immer";

import { MemberType } from "./actions";

export const INITIAL_STATE = {
  photo: null,
  user: {},
  profile: {},
  members: [],
  member_edit: null,
  optionsMember: [],
  all_users: null,
  status:null
};

export default function member(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case MemberType.USER: {
        draft.photo = action.payload.photo;
        draft.user = action.payload.user;
        break;
      }
      case MemberType.SET_LIST: {
        draft.members = action.payload.list;
        draft.status = action.payload.status;
        break;
      }
      case MemberType.STATUS_MEMBER: {
        draft.status = action.payload.status;
        break;
      }
      case MemberType.SET_USERS: {
        draft.all_users = action.payload.all_users;
        break;
      }
      case MemberType.MEMBER_INFO_TO_EDIT: {
        draft.member_edit = action.payload.member;
        break;
      }
      case MemberType.SET_PROFILE: {
        draft.profile = action.payload.profile;
        break;
      }
      case MemberType.SET_OPTIONS_MEMBER: {
        draft.optionsMember = action.payload.list;
        break;
      }
      default:
    }
  });
}
